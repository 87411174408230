import axios from "axios";

// Create a custom instance for supplier profile user requests
export const supplierProfileAxios = axios.create({
  baseURL:process.env.REACT_APP_BASE_URL || "http://localhost:4000",
});

// Add request interceptor for common headers
supplierProfileAxios.interceptors.request.use(
  (config) => {
    // You can add common headers here if needed
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
supplierProfileAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle common errors here
    return Promise.reject(error);
  }
);

export default axios; // Export default axios without modifications
