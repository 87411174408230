import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetSupplierBySlug } from '../../../hooks/profile';
import Cart from './Cart';
import Loader from '../../../Components/Loader';
import { AppDownloadButton, AppPromoBanner } from './AppPromotion';
import { DEFAULT_THEME } from '../../../constants/theme';

// Reuse the same color adjustment function from SupplierProfile
const adjustColor = (color, percent) => {
  try {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;
    return `#${(
      (1 << 24) |
      ((R < 255 ? (R < 1 ? 0 : R) : 255) << 16) |
      ((G < 255 ? (G < 1 ? 0 : G) : 255) << 8) |
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`;
  } catch (error) {
    console.warn("Error adjusting color:", error);
    return color;
  }
};

const CartWrapper = () => {
  const { supplierName } = useParams();
  const { data: supplierData, isLoading } = useGetSupplierBySlug(supplierName);

  if (isLoading) {
    return <Loader />;
  }

  if (!supplierData?.responseData?.supplier) {
    return null;
  }

  const supplier = supplierData.responseData.supplier;
  const theme = {
    primary: supplier?.theme?.primary || DEFAULT_THEME.primary,
    secondary: supplier?.theme?.secondary || DEFAULT_THEME.secondary,
    primaryHover: adjustColor(supplier?.theme?.primary || DEFAULT_THEME.primary, -10),
    secondaryHover: adjustColor(supplier?.theme?.secondary || DEFAULT_THEME.secondary, -10),
  };

  return (
    <>
      <AppPromoBanner theme={theme} />
      <Cart supplier={supplier} theme={theme} />
      {/* <AppDownloadButton theme={theme} /> */}
    </>
  );
};

export default CartWrapper; 