import React, { useContext, useState, useEffect } from "react";
import nav_ic1 from "../../assets/images/icons/nav_ic1.svg";
import nav_ic2 from "../../assets/images/icons/nav_ic2.svg";
import nav_ic3 from "../../assets/images/icons/nav_ic3.svg";
import nav_ic4 from "../../assets/images/icons/nav_ic4.svg";
import nav_ic5 from "../../assets/images/icons/nav_ic5.svg";
import nav_ic6 from "../../assets/images/icons/nav_ic6.svg";
import nav_ic7 from "../../assets/images/icons/nav_ic7.svg";
import nav_ic8 from "../../assets/images/icons/nav_ic8.svg";
import nav_ic9 from "../../assets/images/icons/nav_ic9.svg";
import nav_ic10 from "../../assets/images/icons/nav_ic10.svg";
import sidebar_ic_arrow from "../../assets/images/icons/sidebar_ic_arrow.svg";
import { contextData } from "../../context/ContextState";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { MdViewList } from "react-icons/md";
import { MdGridView } from "react-icons/md";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { FaChartLine } from "react-icons/fa";


function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { menuTogge, setmenuTogge, handleToggle } = useContext(contextData);
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("registration_step");
    navigate("/");
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleItemClick = () => {
    if (windowWidth <= 768) {
      // assuming 768px as a breakpoint for mobile/tablet
      setmenuTogge(!menuTogge);
    }
  };

  return (
    <div className={`main-sidebar ${menuTogge ? "dash-menu-togge" : " "}`}>
      <div className="arrow-wrapper">
        <button onClick={handleToggle} className="sidebar-arrow">
          <img src={sidebar_ic_arrow} alt="" />
        </button>
      </div>
      <div className="sidebar">
        <div className="side-bar">
          <div className="sidebar-action">
            <a href="">My Wallet</a>
            <span></span>
            <a href="">Support</a>
          </div>
          <div className="side-navbar">
            <p>Home Page</p>
            <ul className="flex flex-col justify-center space-y-2">
              <li
                onClick={handleItemClick}
                className={
                  location.pathname === "/dashboard"
                    ? "bg-pink-800 rounded-md pt-2 px-1 mt-1 mb-1 pb-2"
                    : ""
                }
              >
                <Link to="/dashboard" className="flex items-center">
                  <MdOutlineSpaceDashboard className="w-6 h-6" />{" "}
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                onClick={handleItemClick}
                className={
                  location.pathname === "/dashboard/jewellery-list"
                    ? "bg-pink-800 rounded-md pt-2 px-1 mt-1 mb-1 pb-2"
                    : ""
                }
              >
                <Link to="jewellery-list" className="flex items-center">
                  <MdViewList className="w-6 h-6" />{" "}
                  <span>Stock Management</span>
                </Link>
              </li>
              {/* <li
                onClick={handleItemClick}
                className={
                  location.pathname === "/dashboard/catalogue"
                    ? "bg-pink-800 rounded-md pt-2 px-1 mt-1 mb-1 pb-2"
                    : ""
                }
              >
                <Link to="catalogue" className="flex items-center">
                  <MdGridView alt="" className="w-6 h-6" />{" "}
                  <span>Catalogues</span>
                </Link> */}
              {/* </li= */}
              <li
                onClick={handleItemClick}
                className={
                  location.pathname === "/dashboard/enquiry"
                    ? "bg-pink-800 rounded-md pt-2 px-1 mt-1 mb-1 pb-2"
                    : ""
                }
              >
                <Link to="enquiry" className="flex items-center">
                  <MdOutlineQuestionAnswer className="w-6 h-6" />{" "}
                  <span>Enquiry</span>
                </Link>
              </li>
              <li
                onClick={handleItemClick}
                className={
                  location.pathname === "/dashboard/order"
                    ? "bg-pink-800 rounded-md pt-2 px-1 mt-1 mb-1 pb-2"
                    : ""
                }
              >
                <Link to="order" className="flex items-center">
                  <RiSecurePaymentFill className="w-6 h-6" />{" "}
                  <span>Orders</span>
                </Link>
              </li>
              <li
                onClick={handleItemClick}
                className={
                  location.pathname === "/dashboard/performance"
                    ? "bg-pink-800 rounded-md pt-2 px-1 mt-1 mb-1 pb-2"
                    : ""
                }
              >
                <Link to="performance" className="flex items-center">
                  <FaChartLine className="w-5 h-5" />{" "}
                  <span>Performance</span>
                </Link>
              </li>
            </ul>
            {/* <ul>
              <li onClick={handleItemClick}>
                <Link to="/dashboard">
                  <img src={nav_ic1} alt="" /> <span>Dashboard</span>
                </Link>
              </li>
              <li onClick={handleItemClick}>
                <Link to="jewellery-list">
                  <img src={nav_ic1} alt="" /> <span>Stock Management</span>
                </Link>
              </li>
              <li onClick={handleItemClick}>
                <Link to="catalogue">
                  <img src={nav_ic2} alt="" /> <span>Catalogues</span>
                </Link>
              </li> */}
            {/* <li>
                           <a href='#'><img src={nav_ic3} alt="" /> <span>Orders</span></a>
                        </li> */}
            {/* <li>
                          <a href='#'><img src={nav_ic4} alt="" /> <span>Return</span></a>
                        </li>
                        <li>
                          <a href='#'><img src={nav_ic5} alt="" /> <span>Payment</span></a>
                        </li> */}
            {/* <li onClick={handleItemClick}>
                <Link to="enquiry">
                  <img src={nav_ic5} alt="" /> <span>Enquiry</span>
                </Link>
              </li>
              <li onClick={handleItemClick}>
                <Link to="order">
                  <img src={nav_ic5} alt="" /> <span>Orders</span>
                </Link>
              </li>
            </ul> */}
          </div>
          {/* <div className="side-navbar">
                    <p>Advertisement</p>
                    <ul>
                        <li>
                            <a href='#'><img src={nav_ic6} alt="" /> <span>Promotion</span></a>
                        </li>
                        <li>
                          <a href='#'><img src={nav_ic7} alt="" /> <span>Jewecle Campaign</span></a>
                        </li>
                        <li>
                            <a href='#'><img src={nav_ic8} alt="" /> <span>Performance</span></a>
                        </li>
                    </ul>
                </div> */}
          <div className="side-navbar">
            {/* <p>Advertisement</p> */}
            <ul>
              {/* <li>
                          <a href='#'><img src={nav_ic9} alt="" /> <span>Setting</span></a>
                        </li> */}
              <li className="cursor-pointer" onClick={handleLogout}>
                <a href="#">
                  <img src={nav_ic10} alt="" /> <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
