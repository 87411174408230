import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

// Use the environment variable or fallback to a default
const API_URL =process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

// Add debug log
console.log('API_URL:', API_URL);

export const useAddToCart = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ supplierId, mobile, productId }) => {
      console.log('Making addToCart request:', {
        url: `${API_URL}/supplier-profile-user/${supplierId}/cart`,
        data: { mobile, productId }
      });
      
      const { data } = await axios.post(`${API_URL}/supplier-profile-user/${supplierId}/cart`, {
        mobile,
        productId,
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cart");
        queryClient.invalidateQueries("cartCount");
      },
    }
  );
};

export const useGetCart = (supplierId, mobile) => {
  return useQuery(
    ["cart", supplierId, mobile],
    async () => {
      const { data } = await axios.get(
        `${API_URL}/supplier-profile-user/${supplierId}/cart?mobile=${mobile}`
      );
      return data;
    },
    {
      enabled: !!supplierId && !!mobile,
    }
  );
};

export const useUpdateCartQuantity = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ supplierId, mobile, productId, quantity }) => {
      const { data } = await axios.put(
        `${API_URL}/supplier-profile-user/${supplierId}/cart/${productId}`,
        {
          mobile,
          quantity,
        }
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cart");
        queryClient.invalidateQueries("cartCount");
      },
    }
  );
};

export const useRemoveFromCart = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ supplierId, mobile, productId }) => {
      await axios.delete(
        `${API_URL}/supplier-profile-user/${supplierId}/cart/${productId}?mobile=${mobile}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cart");
        queryClient.invalidateQueries("cartCount");
      },
    }
  );
};

export const useClearCart = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ supplierId, mobile }) => {
      await axios.delete(
        `${API_URL}/supplier-profile-user/${supplierId}/cart?mobile=${mobile}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cart");
        queryClient.invalidateQueries("cartCount");
      },
    }
  );
};

export const useGetCartCount = (supplierId, mobile) => {
  return useQuery(
    ["cartCount", supplierId, mobile],
    async () => {
      const { data } = await axios.get(
        `${API_URL}/supplier-profile-user/${supplierId}/cart/count?mobile=${mobile}`
      );
      return data;
    },
    {
      enabled: !!supplierId && !!mobile,
    }
  );
}; 