import React, { useState } from 'react';

export const AppDownloadButton = ({ theme }) => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.jewecle&pcampaignid=web_share"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 z-50 flex items-center gap-2 px-4 py-2 rounded-xl text-white shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-105"
      style={{ 
        background: 'linear-gradient(to right, #3B82F6, #8B5CF6)'
      }}
    >
      <img 
        src={require("../../../assets/images/logo192.png")} 
        alt="Jewecle" 
        className="w-8 h-8"
      />
      <div className="flex flex-col">
        <span className="text-xs opacity-90">Download</span>
        <span className="text-sm font-semibold">Jewecle B2B App</span>
      </div>
    </a>
  );
};

export const AppPromoBanner = ({ theme }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div 
      className="relative text-white py-2 px-4"
      style={{
        background: 'linear-gradient(to right, #3B82F6, #8B5CF6)'
      }}
    >
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center gap-3">
          <img 
            src={require("../../../assets/images/logo192.png")} 
            alt="Jewecle" 
            className="w-6 h-6"
          />
          <p className="text-sm">
            Get unlimited access to jewellery designs with Jewecle B2B App
          </p>
        </div>
        <div className="flex items-center gap-3">
          <a
            href="https://play.google.com/store/apps/details?id=com.jewecle&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
            className="whitespace-nowrap text-sm px-2 sm:px-3 py-1 rounded-lg bg-white text-blue-500 font-medium hover:bg-opacity-90"
          >
            Download Now
          </a>
          <button 
            onClick={() => setIsVisible(false)}
            className="text-white opacity-70 hover:opacity-100"
          >
            <i className="fas fa-times" />
          </button>
        </div>
      </div>
    </div>
  );
};

export const HeaderPromoSection = ({ theme }) => {
  return (
    <div className="flex items-center gap-2 mt-2 text-sm text-white/80">
      <i className="fas fa-mobile-alt"></i>
      <span>Get better experience on our</span>
      <a
        href="https://play.google.com/store/apps/details?id=com.jewecle&pcampaignid=web_share"
        target="_blank"
        rel="noopener noreferrer"
        className="font-medium text-white hover:underline"
      >
        B2B App
      </a>
    </div>
  );
};
