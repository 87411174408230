import { useEffect } from 'react';

const updateFavicon = (logoUrl) => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  
  const finalUrl = logoUrl.startsWith('http') 
    ? logoUrl 
    : `${process.env.REACT_APP_CLOUDFRONT_URL}${logoUrl}`;
    
  link.href = finalUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const useFavicon = (logoUrl) => {
  useEffect(() => {
    const originalFavicon = document.querySelector("link[rel*='icon']")?.href || '/favicon.ico';

    if (logoUrl) {
      updateFavicon(logoUrl);

      return () => {
        updateFavicon(originalFavicon);
      };
    }
  }, [logoUrl]);
}; 