import React, { useState, useEffect } from 'react';
import FullscreenImageViewer from './FullscreenImageViewer';
import { useSubmitSupplierProfileUser } from "../../../hooks/supplierProfileUser";
import { useARTryon } from "../../../hooks/profile";
import { toast } from "react-toastify";
import { State, City } from 'country-state-city';

// Add AR View Modal component
const ARViewModal = ({ 
  isOpen, 
  onClose, 
  theme,
  supplierId,
  selectedProduct,
  onSubmit,
  setIsARModalOpen 
}) => {
  const [formData, setFormData] = useState({
    mobile: "",
    businessName: "",
    city: "",
    state: "",
  });

  const [errors, setErrors] = useState({
    mobile: "",
    city: "",
    state: "",
  });

  const [selectedState, setSelectedState] = useState("");
  const [citySearchQuery, setCitySearchQuery] = useState("");
  const [showCityDropdown, setShowCityDropdown] = useState(false);

  // Get all Indian states
  const stateOptions = React.useMemo(() => {
    return State.getStatesOfCountry('IN');
  }, []);

  // Get cities based on selected state
  const citiesForState = React.useMemo(() => {
    if (!selectedState) return [];
    return City.getCitiesOfState('IN', selectedState);
  }, [selectedState]);

  // Filter cities based on search query
  const filteredCities = React.useMemo(() => {
    if (!citySearchQuery) return [];
    return citiesForState
      .filter(city => 
        city.name.toLowerCase().includes(citySearchQuery.toLowerCase())
      )
      .slice(0, 5);
  }, [citySearchQuery, citiesForState]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Add validation function
  const validateMobile = (number) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    if (!number) {
      return "Mobile number is required";
    }
    if (!mobileRegex.test(number)) {
      return "Please enter a valid 10-digit mobile number";
    }
    return "";
  };

  const validateCity = (city) => {
    if (!city) {
      return "City is required";
    }
    if (!citiesForState.some(c => c.name === city)) {
      return "Please select a valid city";
    }
    return "";
  };

  // Update handle input change
  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Only allow digits
    setFormData(prev => ({ ...prev, mobile: value }));
    setErrors(prev => ({ ...prev, mobile: validateMobile(value) }));
  };

  const handleStateChange = (e) => {
    const stateCode = e.target.value;
    const stateName = stateOptions.find(state => state.isoCode === stateCode)?.name || '';
    setSelectedState(stateCode);
    setFormData(prev => ({ ...prev, state: stateName, city: '' }));
    setCitySearchQuery('');
  };

  const handleCitySelect = (cityName) => {
    setFormData(prev => ({ ...prev, city: cityName }));
    setCitySearchQuery(cityName);
    setShowCityDropdown(false);
    setErrors(prev => ({ ...prev, city: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all fields
    const mobileError = validateMobile(formData.mobile);
    const cityError = validateCity(formData.city);
    const stateError = !formData.state ? "State is required" : "";

    if (mobileError || cityError || stateError) {
      setErrors({
        mobile: mobileError,
        city: cityError,
        state: stateError,
      });
      return;
    }
    
    try {
      await onSubmit(formData);
      
      setIsARModalOpen(false);
      toast.success("Request submitted successfully!");
    } catch (error) {
      console.error("Failed to submit request:", error);
      toast.error(error.response?.data?.message || "Failed to submit request. Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed z-[100]"
      style={{
        top: `${window.scrollY}px`,
        left: 0,
        right: 0,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateY(-19%)'
      }}
    >
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      <div 
        className="relative z-[101] bg-white rounded-2xl p-6 w-full max-w-md mx-4"
        style={{
          maxHeight: '90vh',
          overflowY: 'auto'
        }}
      >
        <h3
          className="text-xl font-semibold mb-4"
          style={{ color: theme.secondary }}
        >
          AR View Request
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Mobile Number
              </label>
              <input
                type="tel"
                required
                maxLength="10"
                className={`w-full px-4 py-2 border rounded-xl focus:outline-none focus:ring-2 ${
                  errors.mobile ? 'border-red-500' : 'border-gray-300'
                }`}
                style={{ 
                  focusRing: errors.mobile ? '#ef4444' : theme.secondary + "40" 
                }}
                value={formData.mobile}
                onChange={handleMobileChange}
                placeholder="Enter 10 digit mobile number"
              />
              {errors.mobile && (
                <p className="mt-1 text-sm text-red-500">{errors.mobile}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                State
              </label>
              <select
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2"
                value={selectedState}
                onChange={handleStateChange}
              >
                <option value="">Select State</option>
                {stateOptions.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                City
              </label>
              <input
                type="text"
                required
                disabled={!selectedState}
                className={`w-full px-4 py-2 border rounded-xl focus:outline-none focus:ring-2 ${
                  errors.city ? 'border-red-500' : 'border-gray-300'
                }`}
                style={{ 
                  focusRing: errors.city ? '#ef4444' : theme.secondary + "40" 
                }}
                value={citySearchQuery}
                onChange={(e) => {
                  setCitySearchQuery(e.target.value);
                  setShowCityDropdown(true);
                }}
                onFocus={() => setShowCityDropdown(true)}
                placeholder={selectedState ? "Search for your city" : "Please select state first"}
              />
              {errors.city && (
                <p className="mt-1 text-sm text-red-500">{errors.city}</p>
              )}
              
              {showCityDropdown && filteredCities.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-xl shadow-lg max-h-60 overflow-auto">
                  {filteredCities.map((city) => (
                    <button
                      key={city.name}
                      type="button"
                      className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none"
                      onClick={() => handleCitySelect(city.name)}
                    >
                      {city.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Business Name
              </label>
              <input
                type="text"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2"
                style={{ focusRing: theme.secondary + "40" }}
                value={formData.businessName}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    businessName: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mt-6 flex gap-3">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-xl text-gray-700 hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex-1 px-4 py-2 rounded-xl text-white transition-colors"
              style={{ backgroundColor: theme.secondary }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Add AR Promotion Modal component
const ARPromotionModal = ({ isOpen, onClose, theme }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className="fixed z-[100]"
      style={{
        top: `${window.scrollY}px`,
        left: 0,
        right: 0,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateY(-19%)'
      }}
    >
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      <div 
        className="relative z-[101] bg-white rounded-2xl p-6 w-full max-w-md mx-4"
        style={{
          maxHeight: '90vh',
          overflowY: 'auto'
        }}
      >
        <div className="flex items-center gap-3 mb-4">
          <img 
            src={require("../../../assets/images/logo192.png")} 
            alt="Jewecle Logo" 
            className="w-12 h-12"
          />
          <div>
            <h3 className="text-xl font-semibold" style={{ color: theme.secondary }}>
              Try Jewecle B2B App
            </h3>
            <p className="text-sm text-gray-500">Your Complete Jewellery Business Solution</p>
          </div>
        </div>
        
        <div className="space-y-4 mb-6">
          <p className="text-gray-600">
            You've reached the maximum number of AR tryons. Download our B2B app to enjoy:
          </p>
          <ul className="space-y-2 text-gray-600">
            <li className="flex items-center gap-2">
              <i className="fas fa-store text-indigo-500"></i>
              <span>Access Multiple Wholesalers</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-cube text-blue-500"></i>
              <span>Unlimited AR Tryons</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-box text-purple-500"></i>
              <span>Exclusive Product Catalog</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-layer-group text-green-500"></i>
              <span>Create Custom Collections</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-heart text-red-500"></i>
              <span>Shortlist Favorite Designs</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-comments text-orange-500"></i>
              <span>Generate & Manage Inquiries</span>
            </li>
          </ul>
        </div>

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-xl text-gray-700 hover:bg-gray-50 transition-colors"
          >
            Close
          </button>
          <a
            href="https://play.google.com/store/apps/details?id=com.jewecle&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-4 py-2 rounded-xl text-white transition-colors hover:opacity-90"
            style={{ backgroundColor: theme.secondary }}
          >
            <i className="fab fa-google-play"></i>
            <span>Download App</span>
          </a>
        </div>
      </div>
    </div>
  );
};

const JewelleryModal = ({ 
  isOpen, 
  onClose, 
  jewellery, 
  theme, 
  supplierId, 
  supplierData,
  onUserInfoUpdate
}) => {
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isARModalOpen, setIsARModalOpen] = useState(false);
  const [showARPromotionModal, setShowARPromotionModal] = useState(false);
  const { mutateAsync: submitProfileUser } = useSubmitSupplierProfileUser();
  const { mutateAsync: tryARView } = useARTryon();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Set the first image as selected by default
      setSelectedImage(jewellery?.images?.[0]);
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, jewellery]);

  // Add new function to check if user info exists
  const checkUserInfoExists = (supplierId) => {
    try {
      const userInfoString = localStorage.getItem('jewecleUserInfo');
      if (!userInfoString) return null;

      const userInfo = JSON.parse(userInfoString);
      const supplierInfo = userInfo[supplierId];

      if (!supplierInfo) return null;

      // Check if the info is not older than 30 days
      const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      if (Date.now() - supplierInfo.timestamp > thirtyDaysInMs) {
        // Remove expired info
        const updatedUserInfo = { ...userInfo };
        delete updatedUserInfo[supplierId];
        localStorage.setItem('jewecleUserInfo', JSON.stringify(updatedUserInfo));
        return null;
      }

      return supplierInfo.data;
    } catch (error) {
      console.error('Error checking user info:', error);
      return null;
    }
  };

  // Add new function to save user info
  const saveUserInfo = (supplierId, formData) => {
    try {
      const userInfoString = localStorage.getItem('jewecleUserInfo');
      const userInfo = userInfoString ? JSON.parse(userInfoString) : {};

      userInfo[supplierId] = {
        data: formData,
        timestamp: Date.now()
      };

      localStorage.setItem('jewecleUserInfo', JSON.stringify(userInfo));
    } catch (error) {
      console.error('Error saving user info:', error);
    }
  };

  // Add AR view click handler
  const handleARViewClick = async () => {
    const existingUserInfo = checkUserInfoExists(supplierId);

    if (existingUserInfo) {
      try {
        const { canTryon, tryonCount, maxTryons } = await tryARView({
          supplierId,
          mobile: existingUserInfo.mobile,
          productId: jewellery._id
        });

        if (canTryon) {
          // Get the token from supplier data
          const token = supplierData?.responseData?.supplier?.token;
          if (!token) {
            toast.error("Unable to access AR view at this time");
            return;
          }

          // Construct and redirect to AR URL
          const arBaseUrl = process.env.REACT_APP_AR_BASE_URL || 'https://tryon.jewecle.com';
          const arUrl = `${arBaseUrl}/?authToken=${token}&jewelId=${jewellery._id}`;
          window.open(arUrl, '_blank');
        } else {
          setShowARPromotionModal(true);
        }
      } catch (error) {
        console.error("Failed to process AR view:", error);
        toast.error(error.response?.data?.message || "Failed to process AR view request");
      }
    } else {
      // Show modal to collect user info
      setIsARModalOpen(true);
    }
  };

  // Modify handleARModalSubmit to call onUserInfoUpdate
  const handleARModalSubmit = async (formData) => {
    try {
      await submitProfileUser({
        ...formData,
        supplierId,
        productId: jewellery._id,
      });
      
      // Save user info
      saveUserInfo(supplierId, formData);
      
      // Call onUserInfoUpdate to sync parent state
      if (onUserInfoUpdate) {
        onUserInfoUpdate(formData);
      }
      
      // Try AR view immediately after submission
      const { canTryon } = await tryARView({
        supplierId,
        mobile: formData.mobile,
        productId: jewellery._id
      });

      if (canTryon) {
        const token = supplierData?.responseData?.supplier?.token;
        if (!token) {
          toast.error("Unable to access AR view at this time");
          return;
        }

        const arBaseUrl = process.env.REACT_APP_AR_BASE_URL || 'https://tryon.jewecle.com';
        const arUrl = `${arBaseUrl}/?authToken=${token}&jewelId=${jewellery._id}`;
        window.open(arUrl, '_blank');
      } else {
        setShowARPromotionModal(true);
      }

      setIsARModalOpen(false);
      return true;
    } catch (error) {
      throw error;
    }
  };

  if (!isOpen || !jewellery) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-40 p-4">
        <div 
          className="bg-white rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto relative"
          onClick={e => e.stopPropagation()}
        >
          <button 
            onClick={onClose}
            className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors z-10"
          >
            <i className="fas fa-times text-gray-500 text-xl"></i>
          </button>
          
          <div className="grid md:grid-cols-2 gap-8 p-6">
            <div>
              <img 
                src={selectedImage || jewellery.images?.[0]} 
                alt={jewellery.product_name || jewellery.name}
                className="w-full h-[400px] object-contain bg-gray-50 rounded-lg cursor-zoom-in hover:opacity-90 transition-opacity"
                onClick={() => setIsFullscreenOpen(true)}
              />
              {jewellery.images?.length > 1 && (
                <div className="grid grid-cols-4 gap-2 mt-4">
                  {jewellery.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`${jewellery.product_name || jewellery.name} - ${index + 1}`}
                      className={`w-full h-20 object-contain bg-gray-50 rounded-md cursor-pointer hover:opacity-90 transition-opacity
                        ${selectedImage === image ? 'ring-2 ring-gray-800' : ''}`}
                      onClick={() => setSelectedImage(image)}
                    />
                  ))}
                </div>
              )}
            </div>
            
            <div className="space-y-6">
              <div>
                <p className="text-sm text-gray-500 mb-1">
                  {jewellery.category_name}
                </p>
                <h2 className="text-2xl font-bold text-gray-800">
                  {jewellery.product_name || jewellery.name}
                </h2>
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                {jewellery.product_details?.gross_weight && (
                  <div>
                    <p className="text-sm text-gray-500">Weight</p>
                    <p className="text-gray-800">{jewellery.product_details.gross_weight}g</p>
                  </div>
                )}
                {jewellery.product_details?.purity && (
                  <div>
                    <p className="text-sm text-gray-500">Purity</p>
                    <p className="text-gray-800">{jewellery.product_details.purity}K</p>
                  </div>
                )}
                {jewellery.product_details?.color && (
                  <div>
                    <p className="text-sm text-gray-500">Color</p>
                    <p className="text-gray-800">{jewellery.product_details.color}</p>
                  </div>
                )}
                {jewellery.product_details?.size && (
                  <div>
                    <p className="text-sm text-gray-500">Size</p>
                    <p className="text-gray-800">{jewellery.product_details.size}</p>
                  </div>
                )}
              </div>

              {/* Dimensions Section */}
              {(jewellery.product_details?.length || 
                jewellery.product_details?.width || 
                jewellery.product_details?.breadth) && (
                <div>
                  <p className="text-sm text-gray-500 mb-1">Dimensions</p>
                  <p className="text-gray-800">
                    {[
                      jewellery.product_details.length,
                      jewellery.product_details.width,
                      jewellery.product_details.breadth
                    ].filter(Boolean).join(' x ')}
                    {' '}{jewellery.product_details.measurement_in}
                  </p>
                </div>
              )}
              
              {jewellery.description && (
                <div>
                  <p className="text-sm text-gray-500 mb-1">Description</p>
                  <p className="text-gray-600 leading-relaxed">
                    {jewellery.description}
                  </p>
                </div>
              )}
              
              {jewellery.isAr && (
                <button 
                  className="w-full text-white py-3 rounded-md transition-colors"
                  style={{ 
                    backgroundColor: theme.secondary,
                    '&:hover': { backgroundColor: theme.secondaryHover }
                  }}
                  onClick={handleARViewClick}
                >
                  <i className="fas fa-cube mr-2"></i>
                  Try with AR
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <FullscreenImageViewer
        isOpen={isFullscreenOpen}
        onClose={() => setIsFullscreenOpen(false)}
        imageUrl={selectedImage || jewellery.images?.[0]}
        images={jewellery.images || []}
        onImageChange={setSelectedImage}
      />

      {/* AR View Modal */}
      <ARViewModal
        isOpen={isARModalOpen}
        onClose={() => setIsARModalOpen(false)}
        theme={theme}
        supplierId={supplierId}
        selectedProduct={jewellery}
        onSubmit={handleARModalSubmit}
        setIsARModalOpen={setIsARModalOpen}
      />

      {/* AR Promotion Modal */}
      <ARPromotionModal
        isOpen={showARPromotionModal}
        onClose={() => setShowARPromotionModal(false)}
        theme={theme}
      />
    </>
  );
};

export default JewelleryModal; 