import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetCart,
  useUpdateCartQuantity,
  useRemoveFromCart,
  useClearCart,
  useGetCartCount,
} from "../../../hooks/cart";
import { useGetSupplierBySlug } from "../../../hooks/profile";
import { useSocket } from "../../../context/socket";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader";
import FullscreenImageViewer from "./FullscreenImageViewer";
import { DEFAULT_THEME } from '../../../constants/theme';
import { usePlaceOrder } from '../../../hooks/order';
import { useFavicon } from '../../../hooks/useFavicon';

const OrderConfirmationModal = ({ isOpen, onClose, onConfirm, theme }) => {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center"
      style={{ top: `${window.scrollY}px` }}
    >
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative bg-white rounded-xl shadow-xl p-6 m-4 max-w-md w-full">
        <h3 
          className="text-xl font-semibold mb-4"
          style={{ color: theme.secondary }}
        >
          Confirm Order
        </h3>
        
        <div className="space-y-4">
          <p className="text-gray-600">
            Are you sure you want to place this order?
          </p>
          
          <div className="bg-gray-50 rounded-lg p-4">
            <p className="text-sm text-gray-600">
              <i className="fas fa-info-circle mr-2 text-blue-500"></i>
              The supplier will review your order and get back to you for confirmation.
            </p>
          </div>
        </div>

        <div className="flex gap-3 mt-6">
          <button
            onClick={onClose}
            className="flex-1 px-4 py-2 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50 transition-all"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="flex-1 px-4 py-2 rounded-lg text-white transition-all hover:scale-105 flex items-center justify-center gap-2"
            style={{
              background: `linear-gradient(45deg, ${theme?.primary}, ${theme?.secondary})`,
            }}
          >
            Confirm Order
          </button>
        </div>
      </div>
    </div>
  );
};

const Cart = ({ supplier: propSupplier, theme: propTheme }) => {
  const socket = useSocket();
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate();
  const { supplierName } = useParams();
  const { data: supplierData, isLoading: supplierLoading } = useGetSupplierBySlug(supplierName, {
    enabled: !propSupplier, // Only fetch if supplier is not provided through props
  });

  // Use props if available, otherwise use fetched data
  const supplier = propSupplier || supplierData?.responseData?.supplier;
  const supplierId = supplier?._id;

  // Get user info from localStorage
  const userInfo = React.useMemo(() => {
    if (!supplierId) return null;
    const storedInfo = localStorage.getItem("jewecleUserInfo");
    if (!storedInfo) return null;
    return JSON.parse(storedInfo)[supplierId]?.data;
  }, [supplierId]);

  const mobile = userInfo?.mobile;

  const { data: cartData, isLoading: cartLoading } = useGetCart(supplierId, mobile);
  const { data: cartCount = 0 } = useGetCartCount(supplierId, mobile);
  const updateQuantity = useUpdateCartQuantity();
  const removeFromCart = useRemoveFromCart();
  const clearCart = useClearCart();

  // Use props theme if available, otherwise use default theme
  const theme = propTheme || {
    primary: supplier?.theme?.primary || DEFAULT_THEME.primary,
    secondary: supplier?.theme?.secondary || DEFAULT_THEME.secondary,
  };

  // Handle back navigation
  const handleBackToShopping = () => {
    window.history.back();
  };

  // Debug logs
  console.log('Cart Component Props:', { propSupplier, propTheme });
  console.log('Supplier Data:', { supplier, supplierId });
  console.log('User Info:', userInfo);
  console.log('Cart Data:', cartData);

  // Redirect if no supplier found
  useEffect(() => {
    if (!supplierLoading && !supplier && !propSupplier) {
      navigate('/');
      toast.error('Supplier not found');
    }
  }, [supplierLoading, supplier, propSupplier, navigate]);

  const handleQuantityChange = async (productId, newQuantity) => {
    try {
      await updateQuantity.mutateAsync({
        supplierId,
        mobile,
        productId,
        quantity: newQuantity,
      });
    } catch (error) {
      toast.error("Failed to update quantity");
    }
  };

  const handleRemoveItem = async (productId) => {
    try {
      await removeFromCart.mutateAsync({
        supplierId,
        mobile,
        productId,
      });
      toast.success("Item removed from cart");
    } catch (error) {
      toast.error("Failed to remove item");
    }
  };

  const handleClearCart = async () => {
    try {
      await clearCart.mutateAsync({
        supplierId,
        mobile,
      });
      toast.success("Cart cleared");
    } catch (error) {
      toast.error("Failed to clear cart");
    }
  };

  const placeOrder = usePlaceOrder();
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  const handlePlaceOrder = async () => {
    setShowConfirmation(true);
  };

  const handleConfirmOrder = async () => {
    try {
      setIsPlacingOrder(true);
      setShowConfirmation(false);

      const orderData = {
        supplierId,
        userInfo: {
          businessName: userInfo.businessName,
          mobile: userInfo.mobile,
          city: userInfo.city,
          state: userInfo.state
        },
        products: cartItems.map(item => ({
          productId: item.productId._id,
          productName: item.productId.product_name,
          category: item.productId.product_category?.name,
          weight: item.productId.product_details?.gross_weight,
          purity: item.productId.product_details?.purity,
          jewecle_id: item.productId.jewecle_id,
          tag_id: item.productId.tag_id
        })),
        totalWeight: cartItems.reduce((total, item) => {
          const weight = parseFloat(item.productId?.product_details?.gross_weight || 0);
          return total + weight;
        }, 0).toFixed(2),
        totalProducts: cartItems.length,
        supplierPhone: supplier.phone
      };

      await placeOrder.mutateAsync(orderData);
      
      // Clear cart after successful order placement
      await clearCart.mutateAsync({
        supplierId,
        mobile: userInfo.mobile
      });

      toast.success("Order placed successfully!");
      navigate(`/profile/${supplierName}`);
    } catch (error) {
      console.error('Error placing order:', error);
      toast.error(error.response?.data?.message || "Failed to place order. Please try again.");
    } finally {
      setIsPlacingOrder(false);
    }
  };

  // Add socket effect
  useEffect(() => {
    if (socket && supplierId) {
      // Join the supplier's room when entering cart page
      socket.emit("joinSupplierRoom", supplierId);
      console.log(`Cart joined room for supplier: ${supplierId}`);

      // Cleanup when leaving cart page
      return () => {
        socket.emit("leaveSupplierRoom", supplierId);
        console.log(`Cart left room for supplier: ${supplierId}`);
      };
    }
  }, [socket, supplierId]);

  // Use the favicon hook
  useFavicon(supplier?.business_logo);

  if (cartLoading || supplierLoading) {
    return <Loader />;
  }

  if (!cartData?.cart?.length) {
    return (
      <div
        className="min-h-screen relative"
        style={{
          backgroundColor: theme?.secondary || "#1F2937",
        }}
      >
        {/* Background gradient overlay */}
        <div
          className="absolute inset-0 opacity-30"
          style={{
            background: `linear-gradient(45deg, ${theme?.primary}40 0%, ${theme?.secondary}40 100%)`,
          }}
        />

        {/* Main content */}
        <div className="relative z-10 max-w-7xl mx-auto px-3 sm:px-6 lg:px-8 py-4 sm:py-8">
          {/* Supplier Header */}
          <header className="mb-4 sm:mb-10 rounded-xl sm:rounded-2xl overflow-hidden backdrop-blur-xl bg-white/10">
            <div className="p-3 sm:p-8">
              <div className="flex flex-col sm:flex-row items-center justify-between gap-3 sm:gap-6">
                <div className="flex items-center gap-3 sm:gap-6 w-full sm:w-auto">
                  <div className="relative">
                    <div className="absolute inset-0 bg-white/20 rounded-full blur-md transform -translate-y-1"></div>
                    <img
                      src={supplier?.business_logo}
                      alt={supplier?.business_name}
                      className="relative w-14 h-14 sm:w-24 sm:h-24 rounded-full object-cover border-2 border-white/50"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <h1 className="text-xl sm:text-4xl font-bold text-white mb-1 sm:mb-2 truncate">
                      {supplier?.business_name}
                    </h1>
                    <div className="flex flex-wrap items-center gap-1 sm:gap-4 text-white/80 text-xs sm:text-base">
                      <span className="flex items-center">
                        <i className="fas fa-map-marker-alt mr-1"></i>
                        {supplier?.city}
                      </span>
                      <span className="hidden sm:inline">•</span>
                      <span className="flex items-center">
                        <i className="fas fa-phone mr-1"></i>
                        {supplier?.phone}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Back Button */}
                <button
                  onClick={handleBackToShopping}
                  className="flex items-center gap-2 px-4 py-2 rounded-xl text-white transition-all hover:scale-105"
                  style={{
                    background: `linear-gradient(45deg, ${theme?.primary}, ${theme?.secondary})`,
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                  <span>Back to Shopping</span>
                </button>
              </div>
            </div>
          </header>

          {/* Empty Cart Message */}
          <div className="backdrop-blur-xl bg-white/95 rounded-xl sm:rounded-2xl p-8 text-center">
            <div className="mb-8">
              <i className="fas fa-shopping-cart text-6xl" style={{ color: theme.secondary }}></i>
            </div>
            <h1 className="text-4xl font-bold mb-4" style={{ color: theme.secondary }}>
              Your Cart is Empty
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Looks like you haven't added any jewellery to your cart yet.
            </p>
            <button
              onClick={handleBackToShopping}
              className="inline-flex items-center px-6 py-3 rounded-xl text-white transition-all hover:scale-105"
              style={{
                background: `linear-gradient(45deg, ${theme?.primary}, ${theme?.secondary})`,
              }}
            >
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    );
  }

  const cartItems = cartData.cart || [];
  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <>
      <div
        className="min-h-screen relative bg-gray-50"
        style={{
          backgroundColor: theme?.secondary || "#1F2937",
        }}
      >
        {/* Background gradient overlay */}
        <div
          className="absolute inset-0 opacity-30"
          style={{
            background: `linear-gradient(45deg, ${theme?.primary}40 0%, ${theme?.secondary}40 100%)`,
          }}
        />

        {/* Main content */}
        <div className="relative z-10 max-w-7xl mx-auto px-3 sm:px-6 py-4">
          {/* Supplier Header - More compact for mobile */}
          <header className="mb-4 rounded-xl overflow-hidden backdrop-blur-xl bg-white/10">
            <div className="p-4">
              <div className="flex items-center justify-between gap-3">
                <div className="flex items-center gap-3">
                  <div className="relative">
                    <div className="absolute inset-0 bg-white/20 rounded-full blur-md transform -translate-y-1"></div>
                    <img
                      src={supplier?.business_logo}
                      alt={supplier?.business_name}
                      className="relative w-12 h-12 sm:w-16 sm:h-16 rounded-full object-cover border-2 border-white/50"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <h1 className="text-lg sm:text-2xl font-bold text-white truncate">
                      {supplier?.business_name}
                    </h1>
                    <div className="flex items-center gap-2 text-white/80 text-xs sm:text-sm">
                      <span className="flex items-center">
                        <i className="fas fa-map-marker-alt mr-1"></i>
                        {supplier?.city}
                      </span>
                      <span>•</span>
                      <span className="flex items-center">
                        <i className="fas fa-phone mr-1"></i>
                        {supplier?.phone}
                      </span>
                    </div>
                  </div>
                </div>

                <button
                  onClick={handleBackToShopping}
                  className="flex items-center gap-2 px-3 py-2 rounded-lg text-white text-sm transition-all hover:scale-105"
                  style={{
                    background: `linear-gradient(45deg, ${theme?.primary}, ${theme?.secondary})`,
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                  <span className="hidden sm:inline">Back</span>
                </button>
              </div>
            </div>
          </header>

          {/* Cart Content - New Design */}
          <div className="flex flex-col lg:flex-row gap-4">
            {/* Cart Items */}
            <div className="flex-1 space-y-4">
              <div className="flex justify-between items-center mb-2 px-2">
                <h2 className="text-lg font-semibold text-white">
                  Cart ({cartItems.length})
                </h2>
                <button
                  onClick={handleClearCart}
                  className="text-white/80 hover:text-white text-sm flex items-center gap-1"
                >
                  <i className="fas fa-trash-alt"></i>
                  <span>Clear</span>
                </button>
              </div>

              {/* Changed to grid for desktop */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {cartItems.map((item) => {
                  const product = item.productId;
                  return (
                    <div
                      key={item._id}
                      className="bg-white/95 backdrop-blur-xl rounded-xl p-3 sm:p-4 shadow-lg border border-gray-100/20 hover:shadow-xl transition-all duration-300"
                    >
                      <div className="flex gap-3 sm:gap-4">
                        {/* Image Section */}
                        <div 
                          className="relative group w-24 sm:w-32 h-24 sm:h-32 flex-shrink-0 cursor-pointer"
                          onClick={() => {
                            setSelectedProduct(product);
                            setSelectedImage(product.images?.[0]);
                            setIsFullscreenOpen(true);
                          }}
                        >
                          <img
                            src={product.images?.[0]}
                            alt={product.name}
                            className="w-full h-full object-contain rounded-lg p-1.5 sm:p-2"
                          />
                          {product.images?.length > 1 && (
                            <div className="absolute bottom-1 right-1 bg-black/60 text-white text-xs px-1.5 py-0.5 rounded">
                              +{product.images.length - 1}
                            </div>
                          )}
                        </div>

                        {/* Content Section */}
                        <div className="flex-1 min-w-0">
                          {/* Top Row - ID and Remove Button */}
                          <div className="flex items-center justify-between mb-2">
                            <span className="text-sm text-gray-500">
                              ID: {product._id.slice(-6).toUpperCase()}
                            </span>
                            <button
                              onClick={() => handleRemoveItem(product._id)}
                              className="text-red-500 hover:text-red-600"
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </div>

                          {/* Product Name and Category */}
                          <div className="mb-3">
                            <h3 className="text-sm font-medium text-gray-900 mb-1">
                              {product.product_name}
                            </h3>
                            <p className="text-xs text-gray-500">
                              {product.product_category?.name}
                            </p>
                          </div>

                          {/* Product Details */}
                          <div className="space-y-2">
                            {/* Weight and Purity */}
                            <div className="flex items-center gap-3">
                              {product.product_details?.gross_weight && (
                                <div className="flex items-center gap-1">
                                  <i className="fas fa-weight-hanging text-gray-400"></i>
                                  <span className="text-sm">{product.product_details.gross_weight}g</span>
                                </div>
                              )}
                              {product.product_details?.purity && (
                                <div className="flex items-center gap-1">
                                  <i className="fas fa-medal text-gray-400"></i>
                                  <span className="text-sm">{product.product_details.purity}K</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Order Summary - Enhanced with user info and total weight */}
            <div className="lg:w-72">
              <div className="bg-white/95 backdrop-blur-xl rounded-lg shadow-sm sticky top-4">
                {/* User Info Section */}
                <div className="p-4 border-b border-gray-100">
                  <h3 className="text-lg font-medium mb-4" style={{ color: theme.secondary }}>
                    Order Summary
                  </h3>
                  {userInfo && (
                    <>
                      <div 
                        className="text-xs px-2 py-1 rounded inline-block mb-3"
                        style={{ 
                          backgroundColor: `${theme.secondary}15`,
                          color: theme.secondary 
                        }}
                      >
                        Buyer Info
                      </div>
                      <div className="space-y-2 text-sm">
                        <div className="flex items-center gap-2 text-gray-600">
                          <i className="fas fa-user text-gray-400"></i>
                          <span className="font-medium">{userInfo.businessName}</span>
                        </div>
                        <div className="flex items-center gap-2 text-gray-600">
                          <i className="fas fa-phone text-gray-400"></i>
                          <span>{userInfo.mobile}</span>
                        </div>
                        <div className="flex items-center gap-2 text-gray-600">
                          <i className="fas fa-map-marker-alt text-gray-400"></i>
                          <span>{userInfo.city}, {userInfo.state}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* Cart Stats */}
                <div className="p-4 space-y-3">
                  <div className="flex justify-between items-center text-sm">
                    <span className="text-gray-600">Products</span>
                    <span className="font-medium" style={{ color: theme.secondary }}>
                      {cartItems.length}
                    </span>
                  </div>

                  {/* Calculate and show total weight */}
                  <div className="flex justify-between items-center text-sm pb-3 border-b border-gray-100">
                    <span className="text-gray-600">Total Weight</span>
                    <div className="flex items-center gap-1.5">
                      <i className="fas fa-weight-hanging text-gray-400"></i>
                      <span className="font-medium" style={{ color: theme.secondary }}>
                        {cartItems.reduce((total, item) => {
                          const weight = parseFloat(item.productId?.product_details?.gross_weight || 0);
                          return total + weight;
                        }, 0).toFixed(2)}g
                      </span>
                    </div>
                  </div>

                  {/* Checkout Button */}
                  <button
                    className="w-full mt-2 py-2.5 px-4 rounded-lg text-white text-sm font-medium transition-all hover:scale-105 flex items-center justify-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed"
                    style={{
                      background: `linear-gradient(45deg, ${theme?.primary}, ${theme?.secondary})`,
                    }}
                    onClick={handlePlaceOrder}
                    disabled={isPlacingOrder}
                  >
                    {isPlacingOrder ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i>
                        <span>Placing Order...</span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-shopping-bag"></i>
                        <span>Place Order</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Image Gallery Modal */}
      {selectedProduct && (
        <FullscreenImageViewer
          isOpen={isFullscreenOpen}
          onClose={() => {
            setIsFullscreenOpen(false);
            setSelectedProduct(null);
            setSelectedImage(null);
          }}
          imageUrl={selectedImage || selectedProduct.images?.[0]}
          images={selectedProduct.images || []}
          onImageChange={setSelectedImage}
        />
      )}

      {/* Add Confirmation Modal */}
      <OrderConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirmOrder}
        theme={theme}
      />
    </>
  );
};

export default Cart; 