// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { toast } from "react-toastify";
import Toast from "../Components/Toaster/Successed";
const SocketContext = createContext();

export function useSocket() {
  return useContext(SocketContext);
}

export function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Connect to the socket server
    const newSocket = io(process.env.REACT_APP_BASE_URL);
    console.log("newSocket: ", newSocket);
    newSocket.emit("subscribe", {
      token: localStorage.getItem("access_token"),
    });
    // Set up event listeners or any other socket configuration here
    newSocket.on("jewellery_inquiry", (data) => {
      // Handle the event globally
      console.log("Received global event:", data);
      toast(<Toast message={"New Inquire generated!"} />);
    });

    setSocket(newSocket);

    // Clean up the socket connection on unmount
    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}
