import React, { useState, useEffect, useMemo } from "react";
import FilterSidebar from "./FilterSidebar";
import { useGetSupplierCategoryJewellery, useARTryon } from "../../../hooks/profile";
import { useSubmitSupplierProfileUser } from "../../../hooks/supplierProfileUser";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../Components/Loader";
import { useSearchParams } from "react-router-dom";
import noResultsImage from "../../../assets/images/no-results-image.svg";
import { toast } from "react-toastify";
import { State, City } from 'country-state-city';
import { useAddToCart } from "../../../hooks/cart";

// Add Modal component
const ARViewModal = ({ 
  isOpen, 
  onClose, 
  theme,
  supplierId,
  selectedProduct,
  onSubmit,
  setIsARModalOpen 
}) => {
  const [formData, setFormData] = useState({
    mobile: "",
    businessName: "",
    city: "",
    state: "",
  });

  // Add error state
  const [errors, setErrors] = useState({
    mobile: "",
    city: "",
    state: "",
  });

  const [selectedState, setSelectedState] = useState("");
  const [citySearchQuery, setCitySearchQuery] = useState("");
  const [showCityDropdown, setShowCityDropdown] = useState(false);

  // Get all Indian states
  const stateOptions = useMemo(() => {
    return State.getStatesOfCountry('IN');
  }, []);

  // Get cities based on selected state
  const citiesForState = useMemo(() => {
    if (!selectedState) return [];
    return City.getCitiesOfState('IN', selectedState);
  }, [selectedState]);

  // Filter cities based on search query
  const filteredCities = useMemo(() => {
    if (!citySearchQuery) return [];
    return citiesForState
      .filter(city => 
        city.name.toLowerCase().includes(citySearchQuery.toLowerCase())
      )
      .slice(0, 5);
  }, [citySearchQuery, citiesForState]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Add validation function
  const validateMobile = (number) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    if (!number) {
      return "Mobile number is required";
    }
    if (!mobileRegex.test(number)) {
      return "Please enter a valid 10-digit mobile number";
    }
    return "";
  };

  const validateCity = (city) => {
    if (!city) {
      return "City is required";
    }
    if (!citiesForState.some(c => c.name === city)) {
      return "Please select a valid city";
    }
    return "";
  };

  // Update handle input change
  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Only allow digits
    setFormData(prev => ({ ...prev, mobile: value }));
    setErrors(prev => ({ ...prev, mobile: validateMobile(value) }));
  };

  const handleStateChange = (e) => {
    const stateCode = e.target.value;
    const stateName = stateOptions.find(state => state.isoCode === stateCode)?.name || '';
    setSelectedState(stateCode);
    setFormData(prev => ({ ...prev, state: stateName, city: '' }));
    setCitySearchQuery('');
  };

  const handleCitySelect = (cityName) => {
    setFormData(prev => ({ ...prev, city: cityName }));
    setCitySearchQuery(cityName);
    setShowCityDropdown(false);
    setErrors(prev => ({ ...prev, city: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all fields
    const mobileError = validateMobile(formData.mobile);
    const cityError = validateCity(formData.city);
    const stateError = !formData.state ? "State is required" : "";

    if (mobileError || cityError || stateError) {
      setErrors({
        mobile: mobileError,
        city: cityError,
        state: stateError,
      });
      return;
    }
    
    try {
      await onSubmit(formData);
      
      setIsARModalOpen(false);
      toast.success("Request submitted successfully!");
    } catch (error) {
      console.error("Failed to submit request:", error);
      toast.error(error.response?.data?.message || "Failed to submit request. Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed z-[100]"
      style={{
        top: `${window.scrollY}px`,
        left: 0,
        right: 0,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateY(-19%)'
      }}
    >
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      <div 
        className="relative z-[101] bg-white rounded-2xl p-6 w-full max-w-md mx-4"
        style={{
          maxHeight: '90vh',
          overflowY: 'auto'
        }}
      >
        <h3
          className="text-xl font-semibold mb-4"
          style={{ color: theme.secondary }}
        >
          AR View Request
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Mobile Number
              </label>
              <input
                type="tel"
                required
                maxLength="10"
                className={`w-full px-4 py-2 border rounded-xl focus:outline-none focus:ring-2 ${
                  errors.mobile ? 'border-red-500' : 'border-gray-300'
                }`}
                style={{ 
                  focusRing: errors.mobile ? '#ef4444' : theme.secondary + "40" 
                }}
                value={formData.mobile}
                onChange={handleMobileChange}
                placeholder="Enter 10 digit mobile number"
              />
              {errors.mobile && (
                <p className="mt-1 text-sm text-red-500">{errors.mobile}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                State
              </label>
              <select
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2"
                value={selectedState}
                onChange={handleStateChange}
              >
                <option value="">Select State</option>
                {stateOptions.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                City
              </label>
              <input
                type="text"
                required
                disabled={!selectedState}
                className={`w-full px-4 py-2 border rounded-xl focus:outline-none focus:ring-2 ${
                  errors.city ? 'border-red-500' : 'border-gray-300'
                }`}
                style={{ 
                  focusRing: errors.city ? '#ef4444' : theme.secondary + "40" 
                }}
                value={citySearchQuery}
                onChange={(e) => {
                  setCitySearchQuery(e.target.value);
                  setShowCityDropdown(true);
                }}
                onFocus={() => setShowCityDropdown(true)}
                placeholder={selectedState ? "Search for your city" : "Please select state first"}
              />
              {errors.city && (
                <p className="mt-1 text-sm text-red-500">{errors.city}</p>
              )}
              
              {showCityDropdown && filteredCities.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-xl shadow-lg max-h-60 overflow-auto">
                  {filteredCities.map((city) => (
                    <button
                      key={city.name}
                      type="button"
                      className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none"
                      onClick={() => handleCitySelect(city.name)}
                    >
                      {city.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Business Name
              </label>
              <input
                type="text"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2"
                style={{ focusRing: theme.secondary + "40" }}
                value={formData.businessName}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    businessName: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mt-6 flex gap-3">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-xl text-gray-700 hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex-1 px-4 py-2 rounded-xl text-white transition-colors"
              style={{ backgroundColor: theme.secondary }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Update the ARPromotionModal component in JewelleryGrid.js
const ARPromotionModal = ({ isOpen, onClose, theme }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className="fixed z-[100]"
      style={{
        top: `${window.scrollY}px`,
        left: 0,
        right: 0,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translateY(-19%)'
      }}
    >
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      <div 
        className="relative z-[101] bg-white rounded-2xl p-6 w-full max-w-md mx-4"
        style={{
          maxHeight: '90vh',
          overflowY: 'auto'
        }}
      >
        <div className="flex items-center gap-3 mb-4">
          <img 
            src={require("../../../assets/images/logo192.png")} 
            alt="Jewecle Logo" 
            className="w-12 h-12"
          />
          <div>
            <h3 className="text-xl font-semibold" style={{ color: theme.secondary }}>
              Try Jewecle B2B App
            </h3>
            <p className="text-sm text-gray-500">Your Complete Jewellery Business Solution</p>
          </div>
        </div>
        
        <div className="space-y-4 mb-6">
          <p className="text-gray-600">
            You've reached the maximum number of AR tryons. Download our B2B app to enjoy:
          </p>
          <ul className="space-y-2 text-gray-600">
          <li className="flex items-center gap-2">
              <i className="fas fa-store text-indigo-500"></i>
              <span>Access Multiple Wholesalers</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-cube text-blue-500"></i>
              <span>Unlimited AR Tryons</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-box text-purple-500"></i>
              <span>Exclusive Product Catalog</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-layer-group text-green-500"></i>
              <span>Create Custom Collections</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-heart text-red-500"></i>
              <span>Shortlist Favorite Designs</span>
            </li>
            <li className="flex items-center gap-2">
              <i className="fas fa-comments text-orange-500"></i>
              <span>Generate & Manage Inquiries</span>
            </li>
           
          </ul>
        </div>

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-xl text-gray-700 hover:bg-gray-50 transition-colors"
          >
            Close
          </button>
          <a
            href="https://play.google.com/store/apps/details?id=com.jewecle&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-4 py-2 rounded-xl text-white transition-colors hover:opacity-90"
            style={{ backgroundColor: theme.secondary }}
          >
            <i className="fab fa-google-play"></i>
            <span>Download App</span>
          </a>
        </div>
      </div>
    </div>
  );
};

const JewelleryGrid = ({
  category,
  supplierId,
  filters,
  setFilters,
  onJewelleryClick,
  onCategoryBack,
  theme = {},
  onUserInfoUpdate,
  supplierData,
}) => {
  // Move the state declarations inside the component
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isARModalOpen, setIsARModalOpen] = useState(false);
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showARPromotionModal, setShowARPromotionModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutateAsync: submitProfileUser } = useSubmitSupplierProfileUser();
  const addToCart = useAddToCart();
  const perPage = 12;
  const { mutateAsync: tryARView } = useARTryon();
  const [addedToCartItems, setAddedToCartItems] = useState(new Set());
  const [userInfo, setUserInfo] = useState(null);

  // Initialize userInfo from localStorage
  useEffect(() => {
    if (supplierId) {
      const storedInfo = localStorage.getItem('jewecleUserInfo');
      if (storedInfo) {
        const parsedInfo = JSON.parse(storedInfo);
        if (parsedInfo[supplierId]?.data) {
          setUserInfo(parsedInfo[supplierId].data);
        }
      }
    }
  }, [supplierId]);

  // Update userInfo when parent updates it
  useEffect(() => {
    const storedInfo = localStorage.getItem('jewecleUserInfo');
    if (storedInfo) {
      const parsedInfo = JSON.parse(storedInfo);
      if (parsedInfo[supplierId]?.data) {
        setUserInfo(parsedInfo[supplierId].data);
      }
    }
  }, [onUserInfoUpdate, supplierId]);

  // Add new function to check if user info exists
  const checkUserInfoExists = (supplierId) => {
    try {
      const userInfoString = localStorage.getItem('jewecleUserInfo');
      if (!userInfoString) return null;

      const userInfo = JSON.parse(userInfoString);
      const supplierInfo = userInfo[supplierId];

      if (!supplierInfo) return null;

      // Check if the info is not older than 30 days
      const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      if (Date.now() - supplierInfo.timestamp > thirtyDaysInMs) {
        // Remove expired info
        const updatedUserInfo = { ...userInfo };
        delete updatedUserInfo[supplierId];
        localStorage.setItem('jewecleUserInfo', JSON.stringify(updatedUserInfo));
        return null;
      }

      return supplierInfo.data;
    } catch (error) {
      console.error('Error checking user info:', error);
      return null;
    }
  };

  // Add new function to save user info
  const saveUserInfo = (supplierId, formData) => {
    try {
      const userInfoString = localStorage.getItem('jewecleUserInfo');
      const userInfo = userInfoString ? JSON.parse(userInfoString) : {};

      userInfo[supplierId] = {
        data: formData,
        timestamp: Date.now()
      };

      localStorage.setItem('jewecleUserInfo', JSON.stringify(userInfo));
    } catch (error) {
      console.error('Error saving user info:', error);
    }
  };

  // Modify handleARViewClick in JewelleryGrid.js
  const handleARViewClick = async (product) => {
    const existingUserInfo = checkUserInfoExists(supplierId);

    if (existingUserInfo) {
      try {
        const { canTryon, tryonCount, maxTryons } = await tryARView({
          supplierId,
          mobile: existingUserInfo.mobile,
          productId: product._id
        });

        if (canTryon) {
          // Get the token from supplier data
          const token = supplierData?.responseData?.supplier?.token;
          if (!token) {
            toast.error("Unable to access AR view at this time");
            return;
          }

          // Construct and redirect to AR URL
          const arBaseUrl = process.env.REACT_APP_AR_BASE_URL || 'https://tryon.jewecle.com';
          const arUrl = `${arBaseUrl}/?authToken=${token}&jewelId=${product._id}`;
          window.open(arUrl, '_blank');
        } else {
          setShowARPromotionModal(true);
        }
      } catch (error) {
        console.error("Failed to process AR view:", error);
        toast.error(error.response?.data?.message || "Failed to process AR view request");
      }
    } else {
      // Show modal to collect user info
      setSelectedProduct(product);
      setIsARModalOpen(true);
    }
  };

  // Create a submit handler for the AR modal
  const handleARModalSubmit = async (formData) => {
    try {
      await submitProfileUser({
        ...formData,
        supplierId,
        productId: selectedProduct._id,
      });
      
      // Save user info and update parent state
      saveUserInfo(supplierId, formData);
      onUserInfoUpdate(formData);
      
      // Try AR view immediately after submission
      const { canTryon } = await tryARView({
        supplierId,
        mobile: formData.mobile,
        productId: selectedProduct._id
      });

      if (canTryon) {
        const token = supplierData?.responseData?.supplier?.token;
        if (!token) {
          toast.error("Unable to access AR view at this time");
          return;
        }

        const arBaseUrl = process.env.REACT_APP_AR_BASE_URL || 'https://tryon.jewecle.com';
        const arUrl = `${arBaseUrl}/?authToken=${token}&jewelId=${selectedProduct._id}`;
        window.open(arUrl, '_blank');
      } else {
        setShowARPromotionModal(true);
      }

      setIsARModalOpen(false);
      return true;
    } catch (error) {
      throw error;
    }
  };

  const handleAddToCart = async (product, e) => {
    e.stopPropagation(); // Prevent card click
    
    if (!supplierId) {
      toast.error("Please wait while supplier information loads...");
      return;
    }

    // Check if item is already added to cart
    if (addedToCartItems.has(product._id)) {
      toast.info("Item already in cart!");
      return;
    }

    const existingUserInfo = checkUserInfoExists(supplierId);

    if (existingUserInfo) {
      try {
        await addToCart.mutateAsync({
          supplierId,
          mobile: existingUserInfo.mobile,
          productId: product._id,
        });
        
        // Add item to the set of added items
        setAddedToCartItems(prev => new Set([...prev, product._id]));
        toast.success("Product added to cart!");
      } catch (error) {
        console.error("Failed to add to cart:", error);
        toast.error(error.response?.data?.message || "Failed to add product to cart. Please try again.");
      }
    } else {
      // Show modal to collect user info
      setSelectedProduct(product);
      setIsUserInfoModalOpen(true);
    }
  };

  const handleUserInfoSubmit = async (formData) => {
    if (!supplierId) {
      toast.error("Invalid supplier information");
      return;
    }

    try {
      // First submit user info
      await submitProfileUser({
        ...formData,
        supplierId,
        productId: selectedProduct._id,
      });
      
      // Save user info in localStorage
      saveUserInfo(supplierId, formData);
      
      // Update parent component's state
      onUserInfoUpdate(formData);
      
      // Then add to cart
      await addToCart.mutateAsync({
        supplierId,
        mobile: formData.mobile,
        productId: selectedProduct._id,
      });
      
      setIsUserInfoModalOpen(false);
      toast.success("Product added to cart!");
    } catch (error) {
      console.error("Failed to process request:", error);
      toast.error(error.response?.data?.message || "Failed to process request. Please try again.");
    }
  };

  // Extract all category IDs when category changes
  const allCategoryIds = React.useMemo(() => {
    if (!category?.product_category) return [];

    const categories = Array.isArray(category.product_category)
      ? category.product_category
      : [category.product_category];

    return categories.map((cat) =>
      typeof cat === "string" ? cat : cat.product_category_id
    );
  }, [category]);

  // Initial setup when category changes
  useEffect(() => {
    if (!category?.category_id) return;

    // Get filters from URL or use defaults with all categories selected
    const urlFilters = {
      subCategory:
        searchParams.getAll("subCategory").length > 0
          ? searchParams.getAll("subCategory")
          : allCategoryIds,
      weight: searchParams.getAll("weight"),
      purity: searchParams.getAll("purity"),
    };

    // Set filters with all categories selected by default
    setFilters(urlFilters);

    // Update URL
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("category", category.category_id);

    // Add all selected categories to URL
    urlFilters.subCategory.forEach((cat) =>
      newSearchParams.append("subCategory", cat)
    );
    urlFilters.weight.forEach((w) => newSearchParams.append("weight", w));
    urlFilters.purity.forEach((p) => newSearchParams.append("purity", p));

    setSearchParams(newSearchParams, { replace: true });
  }, [category?.category_id, allCategoryIds]);

  // Update URL when filters change
  useEffect(() => {
    if (!category?.category_id) return;

    const newSearchParams = new URLSearchParams();

    // Add category
    newSearchParams.set("category", category.category_id);

    // Add all filters to URL
    filters.subCategory.forEach((cat) =>
      newSearchParams.append("subCategory", cat)
    );
    filters.weight.forEach((w) => newSearchParams.append("weight", w));
    filters.purity.forEach((p) => newSearchParams.append("purity", p));

    // Update URL without replacing history
    setSearchParams(newSearchParams, { replace: false });
  }, [filters, category?.category_id]);

  // Memoize query parameters
  const queryConfig = React.useMemo(
    () => ({
      supplierId,
      categoryId: category?.category_id,
      perPage,
      filters,
    }),
    [supplierId, category?.category_id, filters]
  );

  // Use query hook with strict memoization
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useGetSupplierCategoryJewellery(
      queryConfig.supplierId,
      queryConfig.categoryId,
      queryConfig.perPage,
      queryConfig.filters
    );

  console.log("data", data);

  // Debug logging
  useEffect(() => {}, [queryConfig]);

  const allJewellery = React.useMemo(() => {
    if (!data?.pages) return [];
    return data.pages.flatMap((page) => page.responseData.data || []);
  }, [data?.pages]);

  const uniqueJewellery = React.useMemo(() => {
    return [...new Map(allJewellery.map((item) => [item._id, item])).values()];
  }, [allJewellery]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      console.log(
        "Product Categories:",
        uniqueJewellery.map((p) => p.product_category)
      );
    }
  }, [category, uniqueJewellery, filters]);

  const filterProduct = React.useCallback(
    (product) => {
      // Apply sub-category filter
      if (
        filters.subCategory.length > 0 &&
        !filters.subCategory.includes(product.product_category)
      ) {
        return false;
      }

      // Apply weight filter
      if (filters.weight.length > 0) {
        const weight = parseFloat(product.product_details?.gross_weight);
        if (!weight) return false;

        const passesWeight = filters.weight.some((range) => {
          const [min, max] = range.replace("g", "").split("-");
          if (max) {
            return weight >= parseFloat(min) && weight <= parseFloat(max);
          }
          return weight >= parseFloat(min);
        });
        if (!passesWeight) return false;
      }

      // Apply purity filter
      if (filters.purity.length > 0) {
        if (
          !filters.purity.includes(product.product_details?.purity?.toString())
        ) {
          return false;
        }
      }

      return true;
    },
    [filters]
  );

  const filteredProducts = React.useMemo(() => {
    if (!uniqueJewellery?.length) return [];
    return uniqueJewellery.filter(filterProduct);
  }, [uniqueJewellery, filterProduct]);

  // Handle infinite scroll next page
  const handleNextPage = React.useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  // Add debug logging
  useEffect(() => {}, [category, supplierId, filters]);

  const handleFilterChange = (type, value) => {
    setFilters((prev) => {
      if (type === "subCategory") {
        // Handle the select all case
        if (value.selectAll && Array.isArray(value.id)) {
          return {
            ...prev,
            subCategory: value.id,
          };
        }
        // Normal category toggle
        const currentValues = prev[type];
        const valueToCheck = value.id;

        return {
          ...prev,
          [type]: currentValues.includes(valueToCheck)
            ? currentValues.filter((v) => v !== valueToCheck)
            : [...currentValues, valueToCheck],
        };
      }

      // Handle other filter types (weight, purity)
      const currentValues = prev[type];
      return {
        ...prev,
        [type]: currentValues.includes(value)
          ? currentValues.filter((v) => v !== value)
          : [...currentValues, value],
      };
    });
  };

  // Add scroll reset on mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Also reset scroll when category changes
  useEffect(() => {
    if (category?.category_id) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [category?.category_id]);

  // Add useEffect to handle body scroll
  useEffect(() => {
    if (isFilterModalOpen) {
      // Prevent background scroll when modal is open
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling when modal is closed
      document.body.style.overflow = "unset";
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isFilterModalOpen]);

  // Add effect to log supplierId for debugging
  useEffect(() => {
    console.log('Current supplierId:', supplierId);
  }, [supplierId]);

  // Add useEffect to initialize addedToCartItems from cart data
  useEffect(() => {
    if (supplierId && userInfo?.mobile) {
      // Get current cart items and update addedToCartItems
      const fetchCartItems = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/supplier-profile-user/${supplierId}/cart?mobile=${userInfo.mobile}`);
          const data = await response.json();
          if (data && Array.isArray(data.responseData)) {
            const cartItemIds = new Set(data.responseData.map(item => item.productId));
            setAddedToCartItems(cartItemIds);
          }
        } catch (error) {
          console.error("Error fetching cart items:", error);
        }
      };
      fetchCartItems();
    }
  }, [supplierId, userInfo]);

  if (isLoading) {
    return <Loader />;
  }

  if (!category?.id && !category?.category_id) {
    return null;
  }

  return (
    <div className="flex flex-col md:flex-row gap-4 sm:gap-6">
      {/* Desktop Sidebar */}
      <div className="hidden md:block w-72">
        <div className="sticky top-6">
          <div className="backdrop-blur-xl bg-white/95 rounded-xl sm:rounded-2xl overflow-hidden border border-gray-200">
            <div className="p-4 sm:p-6 border-b border-gray-200">
              <h2
                className="text-lg sm:text-xl font-semibold"
                style={{ color: theme.secondary }}
              >
                Filters
              </h2>
            </div>
            <div className="p-4 sm:p-6">
              <FilterSidebar
                filters={filters}
                setFilters={setFilters}
                isModal={false}
                selectedCategory={category}
                onFilterChange={handleFilterChange}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 min-w-0">
        {/* Header Section */}
        <div
          className="backdrop-blur-xl bg-white/95 rounded-xl sm:rounded-2xl p-4 sm:p-6 mb-4 sm:mb-6 sticky top-0 z-50"
          style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
        >
          <div className="flex sm:flex-row items-start sm:items-center justify-between gap-3 sm:gap-4">
            <div className="flex items-center gap-3 sm:gap-4">
              <button
                onClick={onCategoryBack}
                className="flex items-center justify-center w-8 h-8 sm:w-10 sm:h-10 rounded-lg sm:rounded-xl transition-colors"
                style={{
                  backgroundColor: `${theme.secondary}20`,
                  color: theme.secondary,
                }}
              >
                <i className="fas fa-arrow-left text-sm sm:text-base"></i>
              </button>
              <div>
                <h1
                  className="text-lg sm:text-2xl font-bold"
                  style={{ color: theme.secondary }}
                >
                  {category?.name}
                </h1>
                <p className="text-xs sm:text-sm mt-0.5 sm:mt-1 text-gray-600">
                  {data.pages[0].responseData.totalCount} products available
                </p>
              </div>
            </div>

            {/* Mobile Filter Button */}
            <div className="flex items-center gap-3 sm:gap-4">
              <button
                onClick={() => setIsFilterModalOpen(true)}
                className="md:hidden flex items-center gap-2 px-3 py-1.5 sm:px-4 sm:py-2 rounded-lg sm:rounded-xl text-sm transition-colors"
                style={{
                  backgroundColor: theme.secondary,
                  color: "#ffffff",
                }}
              >
                <i className="fas fa-filter"></i>
                <span>Filters</span>
              </button>
            </div>
          </div>
        </div>

        {/* Products Grid */}
        <div className="backdrop-blur-xl bg-white/95 rounded-xl sm:rounded-2xl p-3 sm:p-6">
          {isLoading ? (
            <div className="flex justify-center py-12">
              <div
                className="animate-spin rounded-full h-12 w-12 border-b-2"
                style={{ borderColor: theme.secondary }}
              ></div>
            </div>
          ) : filteredProducts.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-12">
              <img
                src={noResultsImage}
                alt="No Results"
                className="w-32 h-32 mb-4"
              />
              <h2
                className="text-lg font-semibold"
                style={{ color: theme.secondary }}
              >
                No Results Found
              </h2>
              <p className="text-sm text-gray-600">
                Try adjusting your filters to find what you're looking for.
              </p>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={filteredProducts.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={
                <div className="flex justify-center py-8">
                  <Loader />
                </div>
              }
              className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-6"
            >
              {filteredProducts.map((product) => (
                <div
                  key={product._id}
                  className="group bg-white rounded-2xl overflow-hidden border border-gray-100 hover:border-gray-200 transition-all duration-300 hover:-translate-y-1 hover:shadow-xl cursor-pointer flex flex-col"
                  onClick={() => onJewelleryClick(product)}
                >
                  <div className="relative w-full">
                    <div className="pt-[100%]">
                      <img
                        src={product.images?.[0]}
                        alt={product.product_name || product.name}
                        className="absolute inset-0 w-full h-full object-contain p-2"
                      />
                    </div>
                    <div
                      className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                      style={{
                        background: `linear-gradient(to top, ${theme.secondary}cc, transparent)`,
                      }}
                    />
                  </div>

                  <div className="p-4 flex flex-col flex-grow">
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-sm truncate flex-1 mr-2" style={{ color: theme.primary }}>
                        {product.category_name}
                      </p>
                      <div className="flex gap-1.5 flex-shrink-0">
                        {product.product_details?.gross_weight && (
                          <span
                            className="px-2 py-0.5 rounded-md text-xs"
                            style={{
                              backgroundColor: `${theme.secondary}10`,
                              color: theme.secondary,
                            }}
                          >
                            {product.product_details.gross_weight}g
                          </span>
                        )}
                        {product.product_details?.purity && (
                          <span
                            className="px-2 py-0.5 rounded-md text-xs"
                            style={{
                              backgroundColor: `${theme.secondary}10`,
                              color: theme.secondary,
                            }}
                          >
                            {product.product_details.purity}K
                          </span>
                        )}
                      </div>
                    </div>

                    <h3
                      className="text-base font-semibold line-clamp-1 mb-3"
                      style={{ color: theme.secondary }}
                    >
                      {product.product_name || product.name}
                    </h3>

                    <div className={`flex flex-col gap-2 ${!product.isAr ? 'mt-auto' : ''}`}>
                      {product.isAr ? (
                        <>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleARViewClick(product);
                            }}
                            className="w-full px-2.5 py-1.5 rounded-lg text-xs sm:text-sm font-medium shadow-sm hover:shadow-md transition-all duration-300 hover:scale-[1.02] flex items-center justify-center text-white"
                            style={{
                              background: `linear-gradient(45deg, ${theme?.primary}, ${theme?.secondary})`
                            }}
                          >
                            <i className="fas fa-cube text-xs sm:text-sm"></i>
                            <span className="ml-1.5">AR View</span>
                          </button>
                          <button
                            onClick={(e) => handleAddToCart(product, e)}
                            className="w-full px-2.5 py-1.5 rounded-lg text-xs sm:text-sm font-medium shadow-sm hover:shadow-md transition-all duration-300 hover:scale-[1.02] flex items-center justify-center"
                            style={{
                              backgroundColor: theme.secondary,
                              color: "#ffffff",
                            }}
                          >
                            <i className="fas fa-shopping-cart text-xs sm:text-sm"></i>
                            <span className="ml-1.5">Add to Cart</span>
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={(e) => handleAddToCart(product, e)}
                          className="w-full px-2.5 py-1.5 rounded-lg text-xs sm:text-sm font-medium shadow-sm hover:shadow-md transition-all duration-300 hover:scale-[1.02] flex items-center justify-center"
                          style={{
                            backgroundColor: theme.secondary,
                            color: "#ffffff",
                          }}
                        >
                          <i className="fas fa-shopping-cart text-xs sm:text-sm"></i>
                          <span className="ml-1.5">Add to Cart</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>

      {/* Mobile Filter Modal */}
      {isFilterModalOpen && (
        <div className="fixed inset-0 z-50 md:hidden">
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
            onClick={() => setIsFilterModalOpen(false)}
          />

          {/* Slide-in Modal from right - Updated for better scrolling */}
          <div
            className="fixed right-0 w-full sm:w-[320px] bg-white shadow-xl"
            style={{
              top: `${window.scrollY}px`,
              maxHeight: "90vh",
              height: "90vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Fixed Header */}
            <div className="flex-shrink-0 h-[60px] px-4 flex items-center justify-between border-b border-gray-200">
              <h3
                className="text-lg font-semibold"
                style={{ color: theme.secondary }}
              >
                Filters
              </h3>
              <button
                onClick={() => setIsFilterModalOpen(false)}
                className="w-1/2 py-2 px-4 rounded-xl text-white font-medium transition-opacity hover:opacity-90"
                style={{ backgroundColor: theme.secondary }}
              >
                Apply Filters
              </button>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto">
              <FilterSidebar
                filters={filters}
                setFilters={setFilters}
                isModal={true}
                onClose={() => setIsFilterModalOpen(false)}
                selectedCategory={category}
                onFilterChange={handleFilterChange}
                theme={theme}
              />
            </div>
          </div>
        </div>
      )}

      {/* AR View Modal */}
      <ARViewModal
        isOpen={isARModalOpen}
        onClose={() => setIsARModalOpen(false)}
        theme={theme}
        supplierId={supplierId}
        selectedProduct={selectedProduct}
        onSubmit={handleARModalSubmit}
        setIsARModalOpen={setIsARModalOpen}
      />

      {/* User Info Modal for Cart */}
      <ARViewModal
        isOpen={isUserInfoModalOpen}
        onClose={() => setIsUserInfoModalOpen(false)}
        theme={theme}
        supplierId={supplierId}
        selectedProduct={selectedProduct}
        onSubmit={handleUserInfoSubmit}
        setIsARModalOpen={setIsUserInfoModalOpen}
      />

      <ARPromotionModal
        isOpen={showARPromotionModal}
        onClose={() => setShowARPromotionModal(false)}
        theme={theme}
      />
    </div>
  );
};

export default JewelleryGrid;
