import { useMutation } from "react-query";
import { supplierProfileAxios } from "../config/axios";

export const useSubmitSupplierProfileUser = () => {
  return useMutation(
    async ({ supplierId, productId, mobile, businessName, city, state }) => {
      const response = await supplierProfileAxios.post(
        "/supplier-profile-user",
        {
          supplierId,
          productId,
          mobile,
          businessName,
          city,
          state
        }
      );
      return response.data;
    }
  );
};
