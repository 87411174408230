import { useMutation } from "react-query";
import axios from "axios";
import Successed from "../Components/Toaster/Successed";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../Components/Toaster/Successed";
import ToastFailed from "../Components/Toaster/Failed";

const API_URL = process.env.REACT_APP_BASE_URL;

export const useGetProducts = () => {
  const getProductsMutation = useMutation(
    async ({ searchValue, gender }) => {
      const response = await axios.get(`${API_URL}/product`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          accept: "*/*",
        },
        params: {
          search: searchValue,
          gender,
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getProductsMutation;
};

export const useUploadProducts = () => {
  const navigate = useNavigate();

  const getProductsMutation = useMutation(
    async ({ data, draft }) => {
      const response = await axios.post(`${API_URL}/jewellery`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          accept: "*/*",
        },
        params: {
          isDraft: draft,
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        navigate("/dashboard/jewellery-list");
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getProductsMutation;
};

export const useMultipleUploadProducts = () => {
  const navigate = useNavigate();

  const getProductsMutation = useMutation(
    async ({ data, draft }) => {
      const response = await axios.post(`${API_URL}/jewellery/multiple`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          accept: "*/*",
        },
        params: {
          isDraft: draft,
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        navigate("/dashboard/jewellery-list");

        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getProductsMutation;
};

export const useGetJewellery = () => {
  const getJewelleryMutation = useMutation(
    async ({
      perPage,
      page,
      state,
      search,
      start,
      weight,
      jewellery_type,
      jewellery_sub_type,
      min_weight,
      max_weight
    }) => {
      console.log("body", {
        perPage,
        page,
        state,
        search,
        start,
        weight,
        jewellery_type,
        jewellery_sub_type,
        min_weight,
        max_weight
      });
      const body = {
        start: start,
        weight: weight,
        search: search,
        state: state ? state : "",
        ...(jewellery_type && {
          jewellery_type,
        }),
        ...(jewellery_sub_type && {
          jewellery_sub_type,
        }),
        ...(min_weight && { min_weight }),
        ...(max_weight && { max_weight }),
      };
      const response = await axios.get(
        `${API_URL}/jewellery/${perPage}/${page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            accept: "*/*",
          },
          params: body,
        }
      );
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getJewelleryMutation;
};

export const useEditJewellery = () => {
  const navigate = useNavigate();

  const editJewelleryMutation = useMutation(
    async ({ data, id }) => {
      const response = await axios.put(`${API_URL}/jewellery/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          accept: "*/*",
        },
        params: {
          isDraft: 0,
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        navigate("/dashboard/jewellery-list");
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return editJewelleryMutation;
};

export const useGetProductById = () => {
  const navigate = useNavigate();

  const getProductByIdMutation = useMutation(
    async ({ id }) => {
      const response = await axios.get(`${API_URL}/product/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          accept: "*/*",
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getProductByIdMutation;
};

export const useDeleteJewellery = () => {
  const deleteJewelleryById = useMutation(
    async ({ id }) => {
      const response = await axios.delete(`${API_URL}/jewellery/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          accept: "*/*",
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return deleteJewelleryById;
};
